import * as tslib_1 from "tslib";
import { Global4Service } from '../services/global4.service';
import * as $ from 'jquery';
import * as i0 from "@angular/core";
import * as i1 from "./global4.service";
var FormService = /** @class */ (function () {
    function FormService(global) {
        this.global = global;
    }
    FormService.prototype.CopyDefaults = function (Source) {
        var Returns = new Array();
        for (var Property in Source) {
            Returns[Property] = Source[Property];
        }
        return (Returns);
    };
    FormService.prototype.CopyData = function (Object, Source) {
        for (var Property in Object) {
            Object[Property] = Source[Property];
        }
    };
    FormService.prototype.GetData = function (Object, Data, Exclusions, Times) {
        if (Exclusions === void 0) { Exclusions = []; }
        if (Times === void 0) { Times = []; }
        for (var Property in Object) {
            if (Exclusions.indexOf(Property) === -1) {
                if (Times.indexOf(Property) !== -1) {
                    Object[Property] = this.global.CheckDate('1900-01-01 ' + Data[Property.replace(/_/g, '')].toString());
                }
                else {
                    if (Data[Property.replace(/_/g, '')] !== undefined) {
                        switch (typeof (Object[Property])) {
                            case 'string':
                                Object[Property] = this.global.decodeHtmlEntity(this.global.IsUndefined(Data[this.global.Replace(Property, '_', '')], '').toString());
                                break;
                            case 'number':
                                Object[Property] = Data[this.global.Replace(Property, '_', '')].toString();
                                break;
                            case 'boolean':
                                Object[Property] = this.global.ToBoolean(Data[this.global.Replace(Property, '_', '')].toString());
                                break;
                            case 'object':
                                Object[Property] = this.global.CheckDate(Data[Property.replace(/_/g, '')].toString());
                                break;
                        }
                    }
                    else {
                        Object[Property] = null;
                    }
                }
            }
        }
    };
    FormService.prototype.SetParameter = function (Tag, ID, Object, AutoCompletes, DateTimes, TempKey, WithTempKey) {
        if (AutoCompletes === void 0) { AutoCompletes = []; }
        if (DateTimes === void 0) { DateTimes = []; }
        if (TempKey === void 0) { TempKey = ''; }
        if (WithTempKey === void 0) { WithTempKey = false; }
        var AutoCompletesList = new Array();
        for (var Object_1 in AutoCompletes) {
            AutoCompletesList.push(Object_1);
        }
        var Parameter = '<' + Tag + ' ' +
            Tag + 'ID="' + ID + '" ';
        if (WithTempKey === true) {
            Parameter += 'Temp' + Tag + 'Key="' + this.global.XMLEncode(TempKey) + '" ';
        }
        for (var Property in Object) {
            if (AutoCompletesList.indexOf(Property) !== -1) {
                Parameter += Property + '="' + this.global.XMLEncode(AutoCompletes[Property]) + '" ';
            }
            else if (DateTimes.indexOf(Property) !== -1) {
                Parameter += Property + '="' + this.global.DateTimeToString(this.global.toISOString(Object[Property])) + '" ';
            }
            else if (Property !== Tag + 'ID') {
                switch (typeof (Object[Property])) {
                    case 'string':
                        Parameter += Property + '="' + this.global.XMLEncode(Object[Property]) + '" ';
                        break;
                    case 'number':
                    case 'boolean':
                        Parameter += Property + '="' + Object[Property].toString() + '" ';
                        break;
                    case 'object':
                        Parameter += Property + '="' + this.global.DateToString(this.global.toISOString(Object[Property])) + '" ';
                        break;
                }
            }
        }
        Parameter += 'UsersKey="' + this.global.XMLEncode(this.global.GetUser('UsersKey')) + '" ';
        Parameter += '/>';
        return (Parameter);
    };
    FormService.prototype.Initialize = function (ParentModule, ModuleName, Label, StoredProcedure, Parameter, Publish) {
        if (Publish === void 0) { Publish = undefined; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.global.Get('Initialize', '', StoredProcedure, Parameter, 'Initializing...', 'A fatal error occurred while trying to execute initialize for ' + Label + '!', 'An unknown error occurred while trying to execute initialize for ' + Label + '!', 'An unknown fatal error occurred while trying to execute initialize for ' + Label + '!', this.global.IsUndefined(Publish, ParentModule.toLowerCase() + ModuleName.toLowerCase() + 'forminitialize'));
                return [2 /*return*/];
            });
        });
    };
    FormService.prototype.Prompt = function (ParentModule, ModuleName, Function, Tag, Label, ID) {
        var _this = this;
        this.global.Alert('Prompt', 'Are you sure you want to ' + Function.toLowerCase() + ' the record?', [
            {
                text: 'No',
                role: 'cancel'
            },
            {
                text: 'Yes',
                handler: function () {
                    _this.DeleteRestore(ParentModule, ModuleName, Function, Tag, Label, ID);
                }
            }
        ]);
    };
    FormService.prototype.DeleteRestore = function (ParentModule, ModuleName, Function, Tag, Label, ID) {
        var LoadMessage;
        if (Function === 'Delete') {
            LoadMessage = 'Deleting...';
        }
        else {
            LoadMessage = 'Restoring...';
        }
        var Parameter;
        Parameter = '<' + ModuleName + ' ' +
            ModuleName + 'ID="' + ID + '" ' +
            'UsersKey="' + this.global.XMLEncode(this.global.GetUser('UsersKey')) + '" ' +
            '/>';
        this.global.Transaction(ModuleName, ModuleName + '_' + Function, Parameter, ParentModule.toLowerCase() + ModuleName.toLowerCase() + 'formresult', LoadMessage, 'A fatal error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!', Label + ' was ' + Function.toLowerCase() + 'd successfully!', 'An unknown error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!');
    };
    FormService.prototype.Save = function (ParentModule, ModuleName, Function, Tag, Label, StoredProcedure, Parameter, CustomMessages, Publish, ShowResult) {
        if (CustomMessages === void 0) { CustomMessages = []; }
        if (Publish === void 0) { Publish = undefined; }
        if (ShowResult === void 0) { ShowResult = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var LoadMessage, FatalErrorMessage, SuccessMessage, UnknownErrorMessage;
            return tslib_1.__generator(this, function (_a) {
                if (this.global.CountArray(CustomMessages) > 0) {
                    LoadMessage = CustomMessages['LoadMessage'];
                    FatalErrorMessage = CustomMessages['FatalErrorMessage'];
                    SuccessMessage = CustomMessages['SuccessMessage'];
                    UnknownErrorMessage = CustomMessages['UnknownErrorMessage'];
                }
                else {
                    switch (Function) {
                        case 'Add':
                            LoadMessage = 'Adding...';
                            SuccessMessage = Label + ' was added successfully!';
                            FatalErrorMessage = 'A fatal error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!';
                            UnknownErrorMessage = 'An unknown error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!';
                            break;
                        case 'Save':
                            LoadMessage = 'Saving...';
                            SuccessMessage = Label + ' was saved successfully!';
                            FatalErrorMessage = 'A fatal error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!';
                            UnknownErrorMessage = 'An unknown error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!';
                            break;
                        case 'Approve':
                            LoadMessage = 'Approving...';
                            SuccessMessage = Label + ' was approved successfully!';
                            FatalErrorMessage = 'A fatal error occurred while trying to execute approving for ' + Label + '!';
                            UnknownErrorMessage = 'An unknown error occurred while trying to execute approving for ' + Label + '!';
                            break;
                        case 'Disapprove':
                            LoadMessage = 'Disapproving...';
                            SuccessMessage = Label + ' was disapproved successfully!';
                            FatalErrorMessage = 'A fatal error occurred while trying to execute disapproving for ' + Label + '!';
                            UnknownErrorMessage = 'An unknown error occurred while trying to execute disapproving for ' + Label + '!';
                            break;
                        case 'Send Back':
                            LoadMessage = 'Sending Back...';
                            SuccessMessage = Label + ' was sent back successfully!';
                            FatalErrorMessage = 'A fatal error occurred while trying to execute send back for ' + Label + '!';
                            UnknownErrorMessage = 'An unknown error occurred while trying to execute send back for ' + Label + '!';
                            break;
                    }
                }
                if (StoredProcedure.trim() === '') {
                    StoredProcedure = ModuleName + '_' + Function;
                }
                this.global.Transaction(ModuleName, StoredProcedure, Parameter, this.global.IsUndefined(Publish, ParentModule.toLowerCase() + ModuleName.toLowerCase() + 'formresult'), LoadMessage, FatalErrorMessage, SuccessMessage, UnknownErrorMessage, {}, ShowResult, Tag);
                return [2 /*return*/];
            });
        });
    };
    FormService.prototype.ShowSublistForm = function (ParentModule, ModuleName) {
        $('#' + ParentModule + 'Base').hide();
        $('#' + ParentModule + ModuleName + 'Container').show();
    };
    FormService.prototype.CloseSublistForm = function (ParentModule, ModuleName) {
        $('#' + ParentModule + 'Base').show();
        $('#' + ParentModule + ModuleName + 'Container').hide();
    };
    FormService.prototype.ClearErrors = function (ModuleName, Errors) {
        if (Errors === void 0) { Errors = []; }
        $('#' + ModuleName + 'Container .errorcontainer').each(function () {
            Errors[$(this).attr('id')] = false;
        });
    };
    FormService.prototype.CheckRequireds = function (ModuleName, entity, Errors, Valid) {
        if (Errors === void 0) { Errors = []; }
        if (Valid === void 0) { Valid = true; }
        for (var node in entity) {
            if ($('#' + ModuleName + node).attr('required') === 'required') {
                switch (typeof (entity[node])) {
                    case 'object':
                        if (this.global.IsUndefined(entity[node], '') === '') {
                            Valid = false;
                            Errors[node + 'Required'] = true;
                        }
                        break;
                    case 'string':
                        if ($('#' + ModuleName + node).attr('type') === 'password') {
                        }
                        else if ($('#' + ModuleName + node).hasClass('numeric') === true) {
                            if (this.global.ParseFloat(entity[node]) <= 0) {
                                Valid = false;
                                Errors[node + 'Required'] = true;
                            }
                        }
                        else if (entity[node].trim() === '') {
                            Valid = false;
                            Errors[node + 'Required'] = true;
                        }
                        break;
                }
            }
        }
        return (Valid);
    };
    FormService.ngInjectableDef = i0.defineInjectable({ factory: function FormService_Factory() { return new FormService(i0.inject(i1.Global4Service)); }, token: FormService, providedIn: "root" });
    return FormService;
}());
export { FormService };
