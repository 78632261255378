import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Events } from './services/events.service';
import { Global4Service } from './services/global4.service';

import {
  faMapMarkerAlt,
  faScrewdriver,
  faMapPin,
  faUpload,
  faBell,
  faAngleDown,
  faAngleRight,
  faTachometerAlt,
  faLock,
  faSignOutAlt,
  faTasks,
  faList,
  faUsers,
  faUserTie,
  faSuitcase,
  faCarSide,
  faFileAlt,
  faParking,
  faBuilding,
  faWallet,
  faCar,
  faClipboardCheck,
  faHome,
  faReceipt,
  faBan,
  faRulerCombined,
  faChartPie,
  faGrin,
  faBullhorn,
  faClipboardList,
  faJedi,
  faSearch,
  faListAlt,
  faCheckSquare,
  faThumbsUp,
  faCheck,
  faProjectDiagram,
  faUserTag,
  faPhoneSquareAlt,
  faPhoneAlt,
  faPhone,
  faHistory,
  faCalendarDay,
  faShieldAlt,
  faHandHolding,
  faRetweet,
  faCommentDots,
  faCreditCard,
  faCheckDouble,
  faCheckCircle,
  faFingerprint,
  faFilePrescription,
  faIdCard,
  faFileInvoiceDollar,
  faMoneyBillAlt,
  faTicketAlt,
  faDolly,
  faDollyFlatbed,
  faFunnelDollar,
  faCoins,
  faChess,
  faLayerGroup,
  faMicrochip,
  faCalculator,
  faDumbbell,
  faBookOpen,
  faMarker,
  faWarehouse,
  faPiggyBank,
  faToolbox,
  faQuestionCircle,
  faClock,
  faHourglass,
  faTag,
  faCashRegister,
  faLeaf,
  faUserClock
} from '@fortawesome/free-solid-svg-icons';

import {
  faHandshake,
  faUserCircle,
  faCaretSquareRight
} from '@fortawesome/free-regular-svg-icons';

import {
  faGalacticRepublic,
  faFirstOrderAlt,
  faWpforms
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})

export class AppComponent {
  faMapMarkerAlt = faMapMarkerAlt;
  faScrewdriver = faScrewdriver;
  faUserClock = faUserClock;
  faLeaf = faLeaf;
  faCashRegister = faCashRegister;
  faHourglass = faHourglass;
  faTag = faTag;
  faClock = faClock;
  faWpforms = faWpforms;
  faQuestionCircle = faQuestionCircle;
  faToolbox = faToolbox;
  faWarehouse = faWarehouse;
  faMarker = faMarker;
  faBookOpen = faBookOpen;
  faDumbbell = faDumbbell;
  faCalculator = faCalculator;
  faMicrochip = faMicrochip;
  faLayerGroup = faLayerGroup;
  faChess = faChess;
  faCoins = faCoins;
  faFunnelDollar = faFunnelDollar;
  faMapPin = faMapPin;
  faFirstOrderAlt = faFirstOrderAlt;
  faDolly = faDolly;
  faDollyFlatbed = faDollyFlatbed;
  faTicketAlt = faTicketAlt;
  faMoneyBillAlt = faMoneyBillAlt;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faIdCard = faIdCard;
  faFilePrescription = faFilePrescription;
  faFingerprint = faFingerprint;
  faUpload = faUpload;
  faCheckCircle = faCheckCircle;
  faCheckDouble = faCheckDouble;
  faCaretSquareRight = faCaretSquareRight;
  faPhone = faPhone;
  faRetweet = faRetweet;
  faHandHolding = faHandHolding;
  faShieldAlt = faShieldAlt;
  faCalendarDay = faCalendarDay;
  faBell = faBell;
  faAngleDown = faAngleDown;
  faAngleRight = faAngleRight;
  faTachometerAlt = faTachometerAlt;
  faLock = faLock;
  faSignOutAlt = faSignOutAlt;
  faHandshake = faHandshake;
  faTasks = faTasks;
  faList = faList;
  faUsers = faUsers;
  faUserTie = faUserTie;
  faGalacticRepublic = faGalacticRepublic;
  faSuitcase = faSuitcase;
  faCarSide = faCarSide;
  faFileAlt = faFileAlt;
  faParking = faParking;
  faBuilding = faBuilding;
  faWallet = faWallet;
  faCar = faCar;
  faClipboardCheck = faClipboardCheck;
  faUserCircle = faUserCircle;
  faHome = faHome;
  faReceipt = faReceipt;
  faBan = faBan;
  faRulerCombined = faRulerCombined;
  faChartPie = faChartPie;
  faGrin = faGrin;
  faBullhorn = faBullhorn;
  faClipboardList = faClipboardList;
  faJedi = faJedi;
  faSearch = faSearch;
  faListAlt = faListAlt;
  faCheckSquare = faCheckSquare;
  faThumbsUp = faThumbsUp;
  faCheck = faCheck;
  faProjectDiagram = faProjectDiagram;
  faUserTag = faUserTag;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faPhoneAlt = faPhoneAlt;
  faHistory = faHistory;
  faCommentDots = faCommentDots;
  faCreditCard = faCreditCard;
  faPiggyBank = faPiggyBank;

  // public Notification: number = 0;
  public Menu: any;

  constructor(
    public global: Global4Service,
    private platform: Platform,
    private events: Events
  ) {
    this.platform.ready().then((readySource) => {
      this.global.platformWidth = this.platform.width();

      this.platform.resize.subscribe(() => {
        this.global.platformWidth = this.platform.width();
      });
    });

    this.Menu = new Array();
    this.global.GetSessionUser();
    // this.CheckNotification();
  }

  Toggle(Menu: string) {
    this.Menu[Menu] = !this.Menu[Menu];
  }

  MenuRoute(Page: string, IsMyFunction: boolean = false) {
    let RowError: boolean = false;
    if (this.global.ParamEditablePage !== '') {
      RowError = this.global.ToBoolean(this.events.publish (this.global.ParamEditablePage.toLowerCase() + 'status'));
    }

    RowError = this.global.IsUndefined(RowError, false);

    if (RowError === false) {
      this.GotoPage (Page, IsMyFunction);
    } else {
      this.global.Alert (
        'Prompt',
        'You have unsaved data. Do you want to continue to move away from this page?',
        [
          {
            text: 'No',
            role: 'cancel'
          },
          {
            text: 'Yes',
            handler: () => {
              this.GotoPage (Page, IsMyFunction);
            }
          }
        ]
      );
    }
  }

  // CheckNotification() {
  //   if ((this.global.GetUser('UsersKey') !== '***admin***') &&
  //       (this.global.GetUser('UsersKey') !== '')) {
  //     let _global: any;
  //     _global = this.global;

  //     _global.CheckNotification();
  //     setInterval(function() {
  //         _global.CheckNotification();
  //       }, 30000);
  //   }
  // }

  GotoPage(Page: string, IsMyFunction: boolean = false) {
    this.global.ParamPage = Page;
    this.global.ParamSourcePage = '';
    this.global.ParamEditablePage = '';
    
    this.global.SetParam ('Page', Page);
    this.global.SetParam ('EditablePage', '');
    this.global.SetParam ('SourcePage', '');
    this.global.SetParam ('Dashboard', 'false');
    this.global.SetParam ('Notification', 'false');
    this.global.SetParam ('MyFunction', IsMyFunction.toString());
    this.global.SetParam ('ParamID', '0');
    this.global.SetParam ('Option1', '0');

    this.global.Loading ('Loading...', 0, '', Page, 'ROOT');
  }
}
