import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Config {

  public ApplicationName: string = 'Toyota La Union';
  public SchemaName: string = 'LaUnion';
  public NotificationAppID: string = '0';

  public User: any = new Array();

  public PageSize: number = 500;
  public EditablePageSize: number = 1000;

  public SourceURL: string;
  public URL: string;
  public NotificationURL: string;
  public UploadURL: string;
  public DeleteURL: string;
  public DefaultURL: string;
  public TemplateURL: string;
  public Directory: string;

  public Debug: boolean = true; // false
  public Version: string = 'v. 20220714.2309'
  
  constructor(
  ) {
/*
    NOTE:
      DefaultURL
      TemplateURL
      Directory
    - use global.XMLEncode
*/

    // this.SourceURL = 'http://localhost:8100/';
    // this.URL = 'http://alderaan/launion/r2t2post.php';
    // this.NotificationURL = 'http://alderaan/launion/onesignal.php';
    // this.UploadURL = 'http://alderaan/launion/tluupload.php';
    // this.DeleteURL = 'http://alderaan/launion/tludelete.php';
    // this.DefaultURL = 'http://alderaan/launion/uploads/';
    // this.TemplateURL = 'http://alderaan/launion/templates/';

    this.SourceURL = 'http://tluinitiative.com/';
    this.URL = 'http://tluinitiative.com/launion/r2t2post.php';
    this.NotificationURL = 'http://tluinitiative.com/launion/onesignal.php';
    this.UploadURL = 'http://tluinitiative.com/launion/tluupload.php';
    this.DeleteURL = 'http://tluinitiative.com/launion/tludelete.php';
    this.DefaultURL = 'http://tluinitiative.com/launion/uploads/';
    this.TemplateURL = 'http://tluinitiative.com/launion/templates/';

    this.Directory = 'C:\\inetpub\\wwwroot\\aofreightph\\uploads\\';

    this.UserDefaults();
  }

  UserDefaults() {
    this.User['UsersKey'] = '';
    this.User['EmployeesKey'] = '';
    this.User['EmployeeName'] = '';
    this.User['FirstName'] = '';
    this.User['SystemRole'] = '';
    this.User['HasSubordinates'] = false;
    this.User['IsInstructor'] = false;
    this.User['Administrator'] = false;
    this.User['ModulesString'] = '';
    this.User['RightsString'] = '';
    this.User['Modules'] = new Array();
    this.User['Rights'] = {};
    this.User['PictureURL'] = '';
    this.User['TokenID'] = '';
  }

  SetUserConfig(Config: string, Value: any = null) {
    if ((['Modules', 'Rights']).indexOf(Config) !== -1) {
      if (this.User[Config + 'String'] !== '') {
        this.User[Config] = JSON.parse(this.User[Config + 'String']);
      }
    } else {
      switch (typeof(this.User[Config])) {
        case 'boolean':
          this.User[Config] = this.ToBoolean(Value);
          break;

        case 'number':
          this.User[Config] = this.ParseFloat(Value);
          break;

        default:
          this.User[Config] = Value;
          break;
      }
    }
  }

  IsUndefined(Parameter: any, Arbitrary: any) {
    if ((Parameter === undefined) &&
        (Arbitrary === undefined)) {
      return '';
    } else if ((Parameter === null) ||
               (Arbitrary === null)) {
      return '';
    } else if (Parameter === undefined) {
      return Arbitrary;
    } else if (typeof(Parameter) === 'string') {
      return (Parameter.trim());
    } else {
      return Parameter;
    }
  }

  ToBoolean(Input: any) {
    if ((Input === undefined) ||
        (Input === null)) {
      return (false);
    } else {
      switch (Input.toString().toUpperCase()) {
        case '1':
        case 'TRUE':
          return true;
          break;
        default:
          return false;
          break;
      }
    }
  }

  ParseFloat(number: string, AllowNegative: boolean = true) {
    if (this.IsUndefined(number, '') !== '') {
      let returnStr: string;
      returnStr = this.IsUndefined(number, '0').toString();
      returnStr = returnStr.replace (/,/g, '');
      if ((parseFloat(returnStr) < 0) &&
          (AllowNegative === false)) {
        return (0);
      } else {
        return (parseFloat(returnStr));
      }
    } else {
      return (0);
    }
  }
}
