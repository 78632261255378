import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoadingService } from './services/loading.service';

import { LoginPageModule } from './pages/login/login.module';
import { LoginPage } from './pages/login/login.page';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPage, data: { preload: true } },
  { path: 'logout', loadChildren: './pages/logout/logout.module#LogoutPageModule' },
  { path: 'comingsoon', loadChildren: './pages/comingsoon/comingsoon.module#ComingsoonPageModule' },

  { path: 'list', loadChildren: './pages/list/list.module#ListPageModule', data: { name: '/list' } },
  // { path: 'uplactivitieslist', loadChildren: './pages/comingsoon/comingsoon.module#ComingsoonPageModule' },
  // { path: 'uplbiometricslist', loadChildren: './pages/comingsoon/comingsoon.module#ComingsoonPageModule' },

  { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule', data: { name: '/dashboard' } },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },

  { path: 'notifications', loadChildren: './pages/notifications/notifications.module#NotificationsPageModule', data: { preload: true } },
  { path: 'changepassword', loadChildren: './pages/changepassword/changepassword.module#ChangePasswordPageModule', data: { preload: true } },

  { path: 'employeeslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'myemployeeslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'myprofile', loadChildren: './pages/employeesform/employeesform.module#EmployeesFormPageModule' },
  { path: 'employeesform', loadChildren: './pages/employeesform/employeesform.module#EmployeesFormPageModule', data: { name: '/employeesform' } },
  { path: 'myemployeesform', loadChildren: './pages/employeesform/employeesform.module#EmployeesFormPageModule', data: { name: '/myemployeesform' } },
  { path: 'userslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'usersform', loadChildren: './pages/usersform/usersform.module#UsersFormPageModule', data: { name: '/usersform' } },

  // { path: 'surveyheaderslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'mysurveyheaderslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'surveyheadersform', loadChildren: './pages/surveyheadersform/surveyheadersform.module#SurveyHeadersFormPageModule', data: { name: '/surveyheadersform' } },
  // { path: 'mysurveyheadersform', loadChildren: './pages/surveyheadersform/surveyheadersform.module#SurveyHeadersFormPageModule', data: { name: '/mysurveyheadersform' } },

  // { path: 'surveyresponseslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'mysurveyresponseslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'surveyresponsesform', loadChildren: './pages/surveyheadersform/surveyheadersform.module#SurveyHeadersFormPageModule', data: { name: '/surveyresponsesform' } },
  // { path: 'mysurveyresponsesform', loadChildren: './pages/surveyheadersform/surveyheadersform.module#SurveyHeadersFormPageModule', data: { name: '/mysurveyresponsesform' } },

  // { path: 'survey', loadChildren: './pages/survey/survey.module#SurveyPageModule' },
  // { path: 'survey/:key', loadChildren: './pages/survey/survey.module#SurveyPageModule' },

  // { path: 'rptutilizationlist', loadChildren: './pages/reports/reports.module#ReportsPageModule' },
  // { path: 'rptactivitieslist', loadChildren: './pages/rptactivitieslist/rptactivitieslist.module#RPTActivitiesListPageModule' },
  // { path: 'rptcustomerslist', loadChildren: './pages/rptcustomerslist/rptcustomerslist.module#RPTCustomersListPageModule' },
  
  // { path: 'filingslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'filingsform', loadChildren: './pages/filingsform/filingsform.module#FilingsFormPageModule', data: { name: '/filingsform' } },
  // { path: 'fileareport', loadChildren: './pages/filingsform/filingsform.module#FilingsFormPageModule' },

  // { path: 'myemployeereceivableslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'employeereceivableslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'employeereceivablesform', loadChildren: './pages/employeereceivablesform/employeereceivablesform.module#EmployeeReceivablesFormPageModule', data: { name: '/employeereceivablesform' } },

  // { path: 'cashadvanceslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'mycashadvanceslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'cashadvanceapprovalslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'cashadvancereceivedlist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'mycashadvancerequest', loadChildren: './pages/cashadvancesform/cashadvancesform.module#CashAdvancesFormPageModule' },
  // { path: 'cashadvancesform', loadChildren: './pages/cashadvancesform/cashadvancesform.module#CashAdvancesFormPageModule', data: { name: '/cashadvancesform' } },
  // { path: 'mycashadvancesform', loadChildren: './pages/cashadvancesform/cashadvancesform.module#CashAdvancesFormPageModule', data: { name: '/mycashadvancesform' } },
  // { path: 'cashadvanceapprovalsform', loadChildren: './pages/cashadvancesform/cashadvancesform.module#CashAdvancesFormPageModule', data: { name: '/cashadvanceapprovalsform' } },
  // { path: 'cashadvancereceivedform', loadChildren: './pages/cashadvancesform/cashadvancesform.module#CashAdvancesFormPageModule', data: { name: '/cashadvancereceivedform' } },

//   { path: 'taxbracketslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
//   { path: 'designationincentiveslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
//   { path: 'teamincentiveslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
//   { path: 'sublevelincentiveslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
//   { path: 'techincentiveslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },

  { path: 'traininginstructorslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },

  { path: 'trainingslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'trainingsform', loadChildren: './pages/trainingsform/trainingsform.module#TrainingsFormPageModule', data: { name: '/trainingsform' } },
  
  { path: 'mytrainingsastrainorlist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'mytrainingsastrainorform', loadChildren: './pages/mytrainingschedulesform/mytrainingschedulesform.module#MyTrainingSchedulesFormPageModule', data: { name: '/mytrainingsastrainorform' } },
  { path: 'myexamsastrainorlist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'myexamsastrainorform', loadChildren: './pages/myexamsastrainorform/myexamsastrainorform.module#MyExamsAsTrainorFormPageModule', data: { name: '/myexamsastrainorform' } },
  { path: 'trainorexamresultsform', loadChildren: './pages/trainorexamresultsform/trainorexamresultsform.module#TrainorExamResultsFormPageModule', data: { name: '/trainorexamresultsform' } },

  { path: 'trainingschedulesform', loadChildren: './pages/trainingschedulesform/trainingschedulesform.module#TrainingSchedulesFormPageModule', data: { name: '/trainingschedulesform' } },

  { path: 'trainingevaluationheaderslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'trainingevaluationheadersform', loadChildren: './pages/trainingevaluationheadersform/trainingevaluationheadersform.module#TrainingEvaluationHeadersFormPageModule', data: { name: '/trainingevaluationheadersform' } },
  { path: 'trainingevaluation', loadChildren: './pages/accesses/accesses.module#AccessesPageModule' },

  { path: 'mytrainingslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'mytrainingsform', loadChildren: './pages/mytrainingschedulesform/mytrainingschedulesform.module#MyTrainingSchedulesFormPageModule', data: { name: '/mytrainingsform' } },
  { path: 'myevaluationsform', loadChildren: './pages/accesses/accesses.module#AccessesPageModule' },

  // { path: 'examheaderslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'examheadersform', loadChildren: './pages/examheadersform/examheadersform.module#ExamHeadersFormPageModule', data: { name: '/examheadersform' } },
  { path: 'myexamheaderslist', loadChildren: './pages/list/list.module#ListPageModule' },

  // { path: 'examscheduleslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'examschedulesform', loadChildren: './pages/examschedulesform/examschedulesform.module#ExamSchedulesFormPageModule', data: { name: '/examschedulesform' } },
  { path: 'examcheckingslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'examcheckingsform', loadChildren: './pages/examcheckingsform/examcheckingsform.module#ExamCheckingsFormPageModule', data: { name: '/examcheckingsform' } },

  { path: 'myexamslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'myexamresultslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'myexamsform', loadChildren: './pages/accesses/accesses.module#AccessesPageModule' },
  { path: 'exam', loadChildren: './pages/accesses/accesses.module#AccessesPageModule' },
  // { path: 'exam/:key', loadChildren: './pages/accesses/accesses.module#AccessesPageModule' },
  // { path: 'myexamheadersform', loadChildren: './pages/examheadersform/examheadersform.module#ExamHeadersFormPageModule', data: { name: '/myexamheadersform' } },

  { path: 'uploadvehicledeliverieslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploadvehicledeliverieslist' } },
  { path: 'uploaddealerincentiveslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploaddealerincentiveslist' } },
  { path: 'uploadloanproceedslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploadloanproceedslist' } },
  { path: 'uploadservicespartsotherslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploadservicespartsotherslist' } },
  { path: 'uploadinquirieslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploadinquirieslist' } },
  { path: 'uploadpartsinvoiceslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploadpartsinvoiceslist' } },
  { path: 'uploadvehiclesaleslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploadvehiclesaleslist' } },
  { path: 'uploadvehiclessaleslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploadvehiclessaleslist' } },
  { path: 'uploaddcrsaplist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploaddcrsaplist' } },
  { path: 'uploaddcrexcellist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploaddcrexcellist' } },
  { path: 'uploadactivitieslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploadactivitieslist' } },
  { path: 'uploademployeeslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: { name: '/uploademployeeslist' } },

  { path: 'dealerincentives', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/dealerincentives' } },
  { path: 'dealerincentiveswithoutvsi', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
  { path: 'dealerincentivesformatchingvalidation', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
  { path: 'dealerincentiveslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/dealerincentiveslist' } },
  { path: 'dealerincentivesanomalieslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/dealerincentivesanomalieslist' } },

  { path: 'loanproceeds', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/loanproceeds' } },
  { path: 'loanproceedswithoutvsi', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
  { path: 'loanproceedsformatchingvalidation', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
  { path: 'loanproceedslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/loanproceedslist' } },
  { path: 'loanproceedsanomalieslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/loanproceedsanomalieslist' } },

  { path: 'servicespartsothers', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/serivcespartsothers' } },
  { path: 'servicespartsotherswithoutsoa', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
  { path: 'servicespartsothersformatchingvalidation', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
  { path: 'servicespartsothersteammembers', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
  { path: 'servicespartsotherslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/servicespartsotherslist' } },
  { path: 'servicespartsothersanomalieslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/servicespartsothersanomalieslist' } },

/* TODO: */
  { path: 'dcrsap', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/dcrsap' } },
  { path: 'dcrsapformatchingvalidation', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
  { path: 'dcrsaplist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/dcrsaplist' } },
  { path: 'dcrsapanomalieslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/dcrsapanomalieslist' } },

  { path: 'dcrexcel', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/dcrexcel' } },
  { path: 'dcrexcelwithoutkey', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
  { path: 'dcrexcellist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/dcrexcellist' } },
  { path: 'dcrexcelanomalieslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: { name: '/dcrexcelanomalieslist' } },

  { path: 'rpttrainingattendanceevaluations', loadChildren: './pages/reports/reports.module#ReportsPageModule' },
  { path: 'rptassessments', loadChildren: './pages/reports/reports.module#ReportsPageModule' },
  { path: 'rptmaterialaccesses', loadChildren: './pages/reports/reports.module#ReportsPageModule' },

//   { path: 'scheduleslist', loadChildren: './pages/list/list.module#ListPageModule' },
//   { path: 'schedulesform', loadChildren: './pages/schedulesform/schedulesform.module#SchedulesFormPageModule', data: { name: '/schedulesform' } },

//   { path: 'leaveslist', loadChildren: './pages/list/list.module#ListPageModule' },
//   { path: 'leavesform', loadChildren: './pages/leavesform/leavesform.module#LeavesFormPageModule', data: { name: '/leavesform' } },
//   { path: 'myleaveslist', loadChildren: './pages/list/list.module#ListPageModule' },
//   { path: 'myleavesform', loadChildren: './pages/leavesform/leavesform.module#LeavesFormPageModule', data: { name: '/myleavesform' } },
//   { path: 'leaveapprovalslist', loadChildren: './pages/list/list.module#ListPageModule' },
//   { path: 'leaveapprovalsform', loadChildren: './pages/leavesform/leavesform.module#LeavesFormPageModule', data: { name: '/leaveapprovalsform' } },
//   { path: 'myleaveapprovalslist', loadChildren: './pages/list/list.module#ListPageModule' },
//   { path: 'myleaveapprovalsform', loadChildren: './pages/leavesform/leavesform.module#LeavesFormPageModule', data: { name: '/myleaveapprovalsform' } },

//   { path: 'clockinform', loadChildren: './pages/clockinform/clockinform.module#ClockInFormPageModule', data: { name: '/clockinform' } },

//   { path: 'mytimeadjustmentrequestform', loadChildren: './pages/timeadjustmentsform/timeadjustmentsform.module#TimeAdjustmentsFormPageModule' },

  { path: 'calendarslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'calendarsform', loadChildren: './pages/calendarsform/calendarsform.module#CalendarsFormPageModule', data: { name: '/calendarsform' } },

  // { path: 'employeesanomalies', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
  // { path: 'employeesanomalieslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule', data: { name: '/employeesanomalieslist' } },
  // { path: 'employeesanomaliesanomalieslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule', data: { name: '/employeesanomaliesanomalieslist' } },

  // { path: 'inquiries', loadChildren: './pages/inquirieslist/inquirieslist.module#InquiriesListPageModule' },
  // { path: 'inquirieslist', loadChildren: './pages/inquirieslist/inquirieslist.module#InquiriesListPageModule', data: { name: '/inquirieslist' } },
  // { path: 'inquiriesanomalieslist', loadChildren: './pages/inquirieslist/inquirieslist.module#InquiriesListPageModule', data: { name: '/inquiriesanomalieslist' } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: LoadingService }),
    LoginPageModule
  ],
  exports: [RouterModule],
  providers: [LoadingService]
})
export class AppRoutingModule {}