import { Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
var ɵ0 = { preload: true }, ɵ1 = { name: '/list' }, ɵ2 = { name: '/dashboard' }, ɵ3 = { preload: true }, ɵ4 = { preload: true }, ɵ5 = { name: '/employeesform' }, ɵ6 = { name: '/myemployeesform' }, ɵ7 = { name: '/usersform' }, ɵ8 = { name: '/trainingsform' }, ɵ9 = { name: '/mytrainingsastrainorform' }, ɵ10 = { name: '/myexamsastrainorform' }, ɵ11 = { name: '/trainorexamresultsform' }, ɵ12 = { name: '/trainingschedulesform' }, ɵ13 = { name: '/trainingevaluationheadersform' }, ɵ14 = { name: '/mytrainingsform' }, ɵ15 = { name: '/examcheckingsform' }, ɵ16 = { name: '/uploadvehicledeliverieslist' }, ɵ17 = { name: '/uploaddealerincentiveslist' }, ɵ18 = { name: '/uploadloanproceedslist' }, ɵ19 = { name: '/uploadservicespartsotherslist' }, ɵ20 = { name: '/uploadinquirieslist' }, ɵ21 = { name: '/uploadpartsinvoiceslist' }, ɵ22 = { name: '/uploadvehiclesaleslist' }, ɵ23 = { name: '/uploadvehiclessaleslist' }, ɵ24 = { name: '/uploaddcrsaplist' }, ɵ25 = { name: '/uploaddcrexcellist' }, ɵ26 = { name: '/uploadactivitieslist' }, ɵ27 = { name: '/uploademployeeslist' }, ɵ28 = { name: '/dealerincentives' }, ɵ29 = { name: '/dealerincentiveslist' }, ɵ30 = { name: '/dealerincentivesanomalieslist' }, ɵ31 = { name: '/loanproceeds' }, ɵ32 = { name: '/loanproceedslist' }, ɵ33 = { name: '/loanproceedsanomalieslist' }, ɵ34 = { name: '/serivcespartsothers' }, ɵ35 = { name: '/servicespartsotherslist' }, ɵ36 = { name: '/servicespartsothersanomalieslist' }, ɵ37 = { name: '/dcrsap' }, ɵ38 = { name: '/dcrsaplist' }, ɵ39 = { name: '/dcrsapanomalieslist' }, ɵ40 = { name: '/dcrexcel' }, ɵ41 = { name: '/dcrexcellist' }, ɵ42 = { name: '/dcrexcelanomalieslist' }, ɵ43 = { name: '/calendarsform' };
var routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginPage, data: ɵ0 },
    { path: 'logout', loadChildren: './pages/logout/logout.module#LogoutPageModule' },
    { path: 'comingsoon', loadChildren: './pages/comingsoon/comingsoon.module#ComingsoonPageModule' },
    { path: 'list', loadChildren: './pages/list/list.module#ListPageModule', data: ɵ1 },
    // { path: 'uplactivitieslist', loadChildren: './pages/comingsoon/comingsoon.module#ComingsoonPageModule' },
    // { path: 'uplbiometricslist', loadChildren: './pages/comingsoon/comingsoon.module#ComingsoonPageModule' },
    { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule', data: ɵ2 },
    { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
    { path: 'notifications', loadChildren: './pages/notifications/notifications.module#NotificationsPageModule', data: ɵ3 },
    { path: 'changepassword', loadChildren: './pages/changepassword/changepassword.module#ChangePasswordPageModule', data: ɵ4 },
    { path: 'employeeslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'myemployeeslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'myprofile', loadChildren: './pages/employeesform/employeesform.module#EmployeesFormPageModule' },
    { path: 'employeesform', loadChildren: './pages/employeesform/employeesform.module#EmployeesFormPageModule', data: ɵ5 },
    { path: 'myemployeesform', loadChildren: './pages/employeesform/employeesform.module#EmployeesFormPageModule', data: ɵ6 },
    { path: 'userslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'usersform', loadChildren: './pages/usersform/usersform.module#UsersFormPageModule', data: ɵ7 },
    // { path: 'surveyheaderslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'mysurveyheaderslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'surveyheadersform', loadChildren: './pages/surveyheadersform/surveyheadersform.module#SurveyHeadersFormPageModule', data: { name: '/surveyheadersform' } },
    // { path: 'mysurveyheadersform', loadChildren: './pages/surveyheadersform/surveyheadersform.module#SurveyHeadersFormPageModule', data: { name: '/mysurveyheadersform' } },
    // { path: 'surveyresponseslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'mysurveyresponseslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'surveyresponsesform', loadChildren: './pages/surveyheadersform/surveyheadersform.module#SurveyHeadersFormPageModule', data: { name: '/surveyresponsesform' } },
    // { path: 'mysurveyresponsesform', loadChildren: './pages/surveyheadersform/surveyheadersform.module#SurveyHeadersFormPageModule', data: { name: '/mysurveyresponsesform' } },
    // { path: 'survey', loadChildren: './pages/survey/survey.module#SurveyPageModule' },
    // { path: 'survey/:key', loadChildren: './pages/survey/survey.module#SurveyPageModule' },
    // { path: 'rptutilizationlist', loadChildren: './pages/reports/reports.module#ReportsPageModule' },
    // { path: 'rptactivitieslist', loadChildren: './pages/rptactivitieslist/rptactivitieslist.module#RPTActivitiesListPageModule' },
    // { path: 'rptcustomerslist', loadChildren: './pages/rptcustomerslist/rptcustomerslist.module#RPTCustomersListPageModule' },
    // { path: 'filingslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'filingsform', loadChildren: './pages/filingsform/filingsform.module#FilingsFormPageModule', data: { name: '/filingsform' } },
    // { path: 'fileareport', loadChildren: './pages/filingsform/filingsform.module#FilingsFormPageModule' },
    // { path: 'myemployeereceivableslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'employeereceivableslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'employeereceivablesform', loadChildren: './pages/employeereceivablesform/employeereceivablesform.module#EmployeeReceivablesFormPageModule', data: { name: '/employeereceivablesform' } },
    // { path: 'cashadvanceslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'mycashadvanceslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'cashadvanceapprovalslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'cashadvancereceivedlist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'mycashadvancerequest', loadChildren: './pages/cashadvancesform/cashadvancesform.module#CashAdvancesFormPageModule' },
    // { path: 'cashadvancesform', loadChildren: './pages/cashadvancesform/cashadvancesform.module#CashAdvancesFormPageModule', data: { name: '/cashadvancesform' } },
    // { path: 'mycashadvancesform', loadChildren: './pages/cashadvancesform/cashadvancesform.module#CashAdvancesFormPageModule', data: { name: '/mycashadvancesform' } },
    // { path: 'cashadvanceapprovalsform', loadChildren: './pages/cashadvancesform/cashadvancesform.module#CashAdvancesFormPageModule', data: { name: '/cashadvanceapprovalsform' } },
    // { path: 'cashadvancereceivedform', loadChildren: './pages/cashadvancesform/cashadvancesform.module#CashAdvancesFormPageModule', data: { name: '/cashadvancereceivedform' } },
    //   { path: 'taxbracketslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
    //   { path: 'designationincentiveslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
    //   { path: 'teamincentiveslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
    //   { path: 'sublevelincentiveslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
    //   { path: 'techincentiveslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
    { path: 'traininginstructorslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
    { path: 'trainingslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'trainingsform', loadChildren: './pages/trainingsform/trainingsform.module#TrainingsFormPageModule', data: ɵ8 },
    { path: 'mytrainingsastrainorlist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'mytrainingsastrainorform', loadChildren: './pages/mytrainingschedulesform/mytrainingschedulesform.module#MyTrainingSchedulesFormPageModule', data: ɵ9 },
    { path: 'myexamsastrainorlist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'myexamsastrainorform', loadChildren: './pages/myexamsastrainorform/myexamsastrainorform.module#MyExamsAsTrainorFormPageModule', data: ɵ10 },
    { path: 'trainorexamresultsform', loadChildren: './pages/trainorexamresultsform/trainorexamresultsform.module#TrainorExamResultsFormPageModule', data: ɵ11 },
    { path: 'trainingschedulesform', loadChildren: './pages/trainingschedulesform/trainingschedulesform.module#TrainingSchedulesFormPageModule', data: ɵ12 },
    { path: 'trainingevaluationheaderslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'trainingevaluationheadersform', loadChildren: './pages/trainingevaluationheadersform/trainingevaluationheadersform.module#TrainingEvaluationHeadersFormPageModule', data: ɵ13 },
    { path: 'trainingevaluation', loadChildren: './pages/accesses/accesses.module#AccessesPageModule' },
    { path: 'mytrainingslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'mytrainingsform', loadChildren: './pages/mytrainingschedulesform/mytrainingschedulesform.module#MyTrainingSchedulesFormPageModule', data: ɵ14 },
    { path: 'myevaluationsform', loadChildren: './pages/accesses/accesses.module#AccessesPageModule' },
    // { path: 'examheaderslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'examheadersform', loadChildren: './pages/examheadersform/examheadersform.module#ExamHeadersFormPageModule', data: { name: '/examheadersform' } },
    { path: 'myexamheaderslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'examscheduleslist', loadChildren: './pages/list/list.module#ListPageModule' },
    // { path: 'examschedulesform', loadChildren: './pages/examschedulesform/examschedulesform.module#ExamSchedulesFormPageModule', data: { name: '/examschedulesform' } },
    { path: 'examcheckingslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'examcheckingsform', loadChildren: './pages/examcheckingsform/examcheckingsform.module#ExamCheckingsFormPageModule', data: ɵ15 },
    { path: 'myexamslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'myexamresultslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'myexamsform', loadChildren: './pages/accesses/accesses.module#AccessesPageModule' },
    { path: 'exam', loadChildren: './pages/accesses/accesses.module#AccessesPageModule' },
    // { path: 'exam/:key', loadChildren: './pages/accesses/accesses.module#AccessesPageModule' },
    // { path: 'myexamheadersform', loadChildren: './pages/examheadersform/examheadersform.module#ExamHeadersFormPageModule', data: { name: '/myexamheadersform' } },
    { path: 'uploadvehicledeliverieslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ16 },
    { path: 'uploaddealerincentiveslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ17 },
    { path: 'uploadloanproceedslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ18 },
    { path: 'uploadservicespartsotherslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ19 },
    { path: 'uploadinquirieslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ20 },
    { path: 'uploadpartsinvoiceslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ21 },
    { path: 'uploadvehiclesaleslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ22 },
    { path: 'uploadvehiclessaleslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ23 },
    { path: 'uploaddcrsaplist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ24 },
    { path: 'uploaddcrexcellist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ25 },
    { path: 'uploadactivitieslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ26 },
    { path: 'uploademployeeslist', loadChildren: './pages/tluuploadlist/tluuploadlist.module#TLUUploadListPageModule', data: ɵ27 },
    { path: 'dealerincentives', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ28 },
    { path: 'dealerincentiveswithoutvsi', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
    { path: 'dealerincentivesformatchingvalidation', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
    { path: 'dealerincentiveslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ29 },
    { path: 'dealerincentivesanomalieslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ30 },
    { path: 'loanproceeds', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ31 },
    { path: 'loanproceedswithoutvsi', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
    { path: 'loanproceedsformatchingvalidation', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
    { path: 'loanproceedslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ32 },
    { path: 'loanproceedsanomalieslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ33 },
    { path: 'servicespartsothers', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ34 },
    { path: 'servicespartsotherswithoutsoa', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
    { path: 'servicespartsothersformatchingvalidation', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
    { path: 'servicespartsothersteammembers', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
    { path: 'servicespartsotherslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ35 },
    { path: 'servicespartsothersanomalieslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ36 },
    /* TODO: */
    { path: 'dcrsap', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ37 },
    { path: 'dcrsapformatchingvalidation', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
    { path: 'dcrsaplist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ38 },
    { path: 'dcrsapanomalieslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ39 },
    { path: 'dcrexcel', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ40 },
    { path: 'dcrexcelwithoutkey', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule' },
    { path: 'dcrexcellist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ41 },
    { path: 'dcrexcelanomalieslist', loadChildren: './pages/fnalisteditable/listeditable.module#ListEditablePageModule', data: ɵ42 },
    { path: 'rpttrainingattendanceevaluations', loadChildren: './pages/reports/reports.module#ReportsPageModule' },
    { path: 'rptassessments', loadChildren: './pages/reports/reports.module#ReportsPageModule' },
    { path: 'rptmaterialaccesses', loadChildren: './pages/reports/reports.module#ReportsPageModule' },
    //   { path: 'scheduleslist', loadChildren: './pages/list/list.module#ListPageModule' },
    //   { path: 'schedulesform', loadChildren: './pages/schedulesform/schedulesform.module#SchedulesFormPageModule', data: { name: '/schedulesform' } },
    //   { path: 'leaveslist', loadChildren: './pages/list/list.module#ListPageModule' },
    //   { path: 'leavesform', loadChildren: './pages/leavesform/leavesform.module#LeavesFormPageModule', data: { name: '/leavesform' } },
    //   { path: 'myleaveslist', loadChildren: './pages/list/list.module#ListPageModule' },
    //   { path: 'myleavesform', loadChildren: './pages/leavesform/leavesform.module#LeavesFormPageModule', data: { name: '/myleavesform' } },
    //   { path: 'leaveapprovalslist', loadChildren: './pages/list/list.module#ListPageModule' },
    //   { path: 'leaveapprovalsform', loadChildren: './pages/leavesform/leavesform.module#LeavesFormPageModule', data: { name: '/leaveapprovalsform' } },
    //   { path: 'myleaveapprovalslist', loadChildren: './pages/list/list.module#ListPageModule' },
    //   { path: 'myleaveapprovalsform', loadChildren: './pages/leavesform/leavesform.module#LeavesFormPageModule', data: { name: '/myleaveapprovalsform' } },
    //   { path: 'clockinform', loadChildren: './pages/clockinform/clockinform.module#ClockInFormPageModule', data: { name: '/clockinform' } },
    //   { path: 'mytimeadjustmentrequestform', loadChildren: './pages/timeadjustmentsform/timeadjustmentsform.module#TimeAdjustmentsFormPageModule' },
    { path: 'calendarslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'calendarsform', loadChildren: './pages/calendarsform/calendarsform.module#CalendarsFormPageModule', data: ɵ43 },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43 };
