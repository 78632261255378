<ion-app>
  <ion-split-pane when="web.MenuShow">
    <ion-menu>
      <ion-header class="menu">
        <div class="menu" style="padding:10px">
          <div class="ion-padding" style="text-align:center">
            <img src="assets/imgs/banner.png" alt="logo" style="width:50%" />
          </div>
          {{ 'Welcome, ' }}
          <h3><b>{{ global.GetUser('FirstName') }}</b></h3>
        </div>
      </ion-header>
      <ion-content class="menu">
        <ion-list class="menu">
          <ion-item button (click)="Toggle('Main');" class="menu">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Main']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Main']"></fa-icon>
            <ion-label>
              <fa-icon [icon]="faHome" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'MAIN' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Main']" class="menu">
            <ion-menu-toggle>
              <ion-item button (click)="MenuRoute('/dashboard')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faTachometerAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Dashboard' }}</strong>
                </ion-label>
              </ion-item>

              <!-- <ion-item button (click)="MenuRoute('/notifications')" class="menu" *ngIf="global.GetUser('UsersKey')!=='***admin***'">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faBell" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Notifications' }}</strong>
                </ion-label>
                <ion-badge color="danger" *ngIf="global.Notification>0">&nbsp;&nbsp;{{ global.Notification }}&nbsp;&nbsp;</ion-badge>
              </ion-item> -->

              <ion-item button (click)="MenuRoute('/changepassword')" class="menu" *ngIf="global.GetUser('UsersKey')!=='***admin***'">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faLock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Change Password' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/settings')" class="menu" *ngIf="global.GetUser('UsersKey')==='***admin***'">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faGalacticRepublic" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Settings' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/logout')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faSignOutAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Logout' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-item button (click)="Toggle('MyFunctions');" class="menu" *ngIf="(global.GetUser('UsersKey')!=='***admin***') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['MyFunctions']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['MyFunctions']"></fa-icon>
            <ion-label>
              <fa-icon [icon]="faGrin" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'MY FUNCTIONS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['MyFunctions'] && (global.GetUser('UsersKey')!=='***admin***')" class="menu">
            <ion-menu-toggle>
              <!-- <ion-item button (click)="MenuRoute('/clockinform', true)" class="menu" *ngIf="(global.GetUser('UsersKey') !== '***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faUserClock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Clock-in / Clock-out' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mytimeadjustmentrequestform', true)" class="menu" *ngIf="(global.GetUser('UsersKey') !== '***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faHourglass" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Time Adjustment Request' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myprofile')" class="menu" *ngIf="(global.GetUser('UsersKey') !== '***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faUserCircle" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'My Profile' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mycashadvancerequest')" class="menu"  *ngIf="(global.GetUser('UsersKey')!=='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faFileInvoiceDollar" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Cash Advance Requests' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mycashadvanceslist', true)" class="menu"  *ngIf="(global.GetUser('UsersKey')!=='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faFileInvoiceDollar" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'My Cash Advances' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/cashadvancereceivedlist', true)" class="menu" *ngIf="(global.GetUser('UsersKey') !== '***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faHandHolding" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Cash Advance for Acknowledgement' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myemployeereceivableslist', true)" class="menu" *ngIf="(global.GetUser('UsersKey')!=='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faFileInvoiceDollar" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'My Receivables' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/fileareport')" class="menu" *ngIf="(global.HasSubordinates === true) && (global.GetUser('UsersKey') !== '***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faFilePrescription" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'File an Employee Report' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/cashadvanceapprovalslist')" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Assistant General Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faThumbsUp" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Cash Advance Approvals' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mycustomerslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Customer Relations Officer') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faSuitcase" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Customers' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/prospectsform', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Group Manager') || (global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'MP (Marketing Professional)') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faSuitcase" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Prospects' }}</strong>
                </ion-label>
              </ion-item> -->

              <ion-item button (click)="MenuRoute('/mytrainingsastrainorlist', true)" class="menu" *ngIf="global.ToBoolean(global.GetUser('IsInstructor')) === true">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faDumbbell" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'TRAINOR: My Trainings' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myexamsastrainorlist', true)" class="menu" *ngIf="global.ToBoolean(global.GetUser('IsInstructor')) === true">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faMarker" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'TRAINOR: My Exam Results' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mytrainingslist', true)" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faDumbbell" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'My Trainings' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myexamslist', true)" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faMarker" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'My Exams' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myexamresultslist', true)" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCheckDouble" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'My Exam Results' }}</strong>
                </ion-label>
              </ion-item>

              <!-- <ion-item button (click)="MenuRoute('/activitieslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'MP (Marketing Professional)') || (global.GetUser('SystemRole') === 'Group Manager') || (global.GetUser('SystemRole') === 'Vehicle Sales Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faPhoneSquareAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Activities' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myvehiclesform', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Sales Admin') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCarSide" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Encoding' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myleaveslist', true)" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faLeaf" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'My Leaves' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myleaveapprovalslist', true)" class="menu" *ngIf="(global.HasSubordinates===true)">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faLeaf" size="2x"></fa-icon>
                <fa-icon slot="end" [icon]="faThumbsUp" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Leave Approval' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myvehicledeliveredlist')" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Sales Admin') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCaretSquareRight" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicles Delivered' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/vehicleinventorieslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Sales Admin') || (global.GetUser('SystemRole') === 'Dealer Sales Trainer') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCarSide" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Inventories' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mydealslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'MP (Marketing Professional)') || (global.GetUser('SystemRole') === 'Group Manager') || (global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Finance Staff') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faHandshake" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Deals' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mydealpaymentinsuranceslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Finance Staff') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <div slot="end">
                  <fa-icon [icon]="faShieldAlt" size="2x"></fa-icon>
                  <fa-icon [icon]="faCreditCard" size="2x"></fa-icon>
                </div>
                <ion-label>
                  <strong>{{ 'Deal Payment and Insurance Info' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mycanceldealslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Group Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faBan" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Cancel Deal Requests' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mycanceldealapprovalslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faThumbsUp" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Cancel Deal Approvals' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myinsurancecoverageslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Customer Relations Officer') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCalendarDay" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Deal Insurance Coverages' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myassigngroupmanagerslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faProjectDiagram" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Assign Vehicle to GRM' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myassignmarketingprofessionalslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Group Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faUserTag" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Assign Vehicle to MP / Customers' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myqualitycheckslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'PDI (Pre Delivery Inspector)') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" size="2x" [icon]="faClipboardList"></fa-icon>
                <ion-label>
                  <strong>{{ 'Quality Check / Stencil and Parking' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myassigntaskslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Group Manager') || (global.GetUser('SystemRole') === 'Accessories Staff') || (global.GetUser('SystemRole') === 'Parts and Accessories Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faList" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Assign Tasks' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myaccessorizingslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'PDI (Pre Delivery Inspector)') || (global.GetUser('SystemRole') === 'Accessorizer') || (global.GetUser('SystemRole') === 'Tinter') || (global.GetUser('SystemRole') === 'Rust Proofer') || (global.GetUser('SystemRole') === 'Rustproofer / Undercoat') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faJedi" size="2x"></fa-icon>
                <ion-label style="font-size: 14px">
                  <strong>{{ 'Carwash / Accessorizing / Rustproof / Undercoat' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myfinalinspectionslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'PDI Supervisor') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faSearch" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Final Inspection' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mydocumentchecklistslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Finance Staff') || (global.GetUser('SystemRole') === 'Finance Supervisor') || (global.GetUser('SystemRole') === 'Finance Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faListAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Document Checklist' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mydocumentcheckingslist')" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Finance Supervisor') || (global.GetUser('SystemRole') === 'Finance Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCheckSquare" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Document Checking' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myoverwriterequestlist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Group Manager') || (global.GetUser('SystemRole') === 'MP (Marketing Professional)') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCommentDots" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Releasing Overwrite Request' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myreleasingoverwriteapprovalslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') ||(global.GetUser('SystemRole') === 'Finance Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faThumbsUp" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Releasing Overwrite Approval' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mygatepasslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Finance Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faTicketAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Gate Pass' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myvehiclereleasinginfolist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Group Manager') || (global.GetUser('SystemRole') === 'MP (Marketing Professional)') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faDollyFlatbed" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Releasing Info' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myreleasingslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Group Manager') || (global.GetUser('SystemRole') === 'MP (Marketing Professional)') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCheck" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Releasing' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myassigndeliverylist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Group Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faList" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Assign Delivery' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myvehicledeliverylist', true)" class="menu" *ngIf="(global.GetUser('UsersKey') !== '***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faDolly" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Delivery' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myvehiclestatuslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Group Manager') || (global.GetUser('SystemRole') === 'MP (Marketing Professional)') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faRulerCombined" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Status' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/mydealreceivableslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Credit and Collection Staff - VSD') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <div slot="end">
                  <fa-icon [icon]="faHandHolding" size="2x"></fa-icon>
                  <fa-icon [icon]="faHandshake" size="2x"></fa-icon>
                </div>
                <ion-label>
                  <strong>{{ 'Loan Proceeeds and Dealer Incentives' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myinsuranceslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Customer Relations Officer') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faShieldAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Insurances' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myinsurancerenewalslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Customer Relations Officer') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <div slot="end">
                  <fa-icon [icon]="faShieldAlt" size="2x"></fa-icon>
                  <fa-icon [icon]="faRetweet" size="2x"></fa-icon>
                </div>
                <ion-label>
                  <strong>{{ 'Insurance Renwewals' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/myinsurancecollectionslist', true)" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Credit and Collection Staff - Insurance') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <div slot="end">
                  <fa-icon [icon]="faHandHolding" size="2x"></fa-icon>
                  <fa-icon [icon]="faShieldAlt" size="2x"></fa-icon>
                </div>
                <ion-label>
                  <strong>{{ 'Insurance for Collections' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/surveyheaderslist')" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Customer Relations Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCheckDouble" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Surveys' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/surveyresponseslist')" class="menu" *ngIf="(global.GetUser('SystemRole') === 'Customer Relations Manager') || ((global.GetUser('UsersKey')==='***admin***') && (global.GetUser('Debug')===true))">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCheckCircle" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Survey Results' }}</strong>
                </ion-label>
              </ion-item>-->
            </ion-menu-toggle>
          </ion-list>

          <ion-item button (click)="Toggle('Profiles');" class="menu" *ngIf="global.WithModule('Employees') || global.WithModule('Users')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Profiles']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Profiles']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faUserCircle" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'PROFILES' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Profiles']" class="menu">
            <ion-menu-toggle>

              <ion-item button (click)="MenuRoute('/employeeslist')" class="menu" *ngIf="global.WithModule('Employees')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faUserTie" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Employees' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/userslist')" class="menu" *ngIf="global.WithModule('Users')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faUsers" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Users' }}</strong>
                </ion-label>
              </ion-item>

              <!-- <ion-item button (click)="MenuRoute('/customerslist')" class="menu" *ngIf="global.WithModule('Customers')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faSuitcase" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Customers' }}</strong>
                </ion-label>
              </ion-item> -->

              <!-- <ion-item button (click)="MenuRoute('/parkingslist')" class="menu" *ngIf="global.WithModule('Parkings')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faParking" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Parkings' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/modelslist')" class="menu" *ngIf="global.WithModule('Models')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCar" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Models' }}</strong>
                </ion-label>
              </ion-item> -->
<!--
              <ion-item button (click)="MenuRoute('/taskslist')" class="menu" *ngIf="global.WithModule('Tasks')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faList" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Tasks' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/paymenttypeslist')" class="menu" *ngIf="global.WithModule('PaymentTypes')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faWallet" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Payment Types' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/documentslist')" class="menu" *ngIf="global.WithModule('Documents')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faFileAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Documents' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/bankslist')" class="menu" *ngIf="global.WithModule('Banks')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faBuilding" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Banks' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/requireddocumentslist')" class="menu" *ngIf="global.WithModule('DocumentRequirementHeaders')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClipboardCheck" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Required Documents' }}</strong>
                </ion-label>
              </ion-item> -->

            </ion-menu-toggle>
          </ion-list>

          <!-- <ion-item button (click)="Toggle('Vehicles');" class="menu" *ngIf="global.WithModule('Vehicles')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Vehicles']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Vehicles']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faCar" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'VEHICLES' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Vehicles']" class="menu">
            <ion-menu-toggle>

              <ion-item button (click)="MenuRoute('/vehicleslist')" class="menu" *ngIf="global.WithModule('Vehicles')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCarSide" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicles' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/vehiclestatuslist')" class="menu" *ngIf="global.WithModule('Vehicles')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faRulerCombined" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Status' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list> -->
<!--
          <ion-item button (click)="Toggle('Transactions');" class="menu" *ngIf="global.WithModule('Deals') || global.WithModule('Checklists') || global.WithModule('Pending Deals') || global.WithModule('Insurances') || global.WithModule('InsuranceCoverages')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Transactions']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Transactions']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faReceipt" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'TRANSACTIONS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Transactions']" class="menu">
            <ion-menu-toggle>
              <ion-item button (click)="MenuRoute('/pendingdealslist')" class="menu" *ngIf="global.WithModule('Pending Deals')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faHistory" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Pending Deals' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/dealslist')" class="menu" *ngIf="global.WithModule('Deals')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faHandshake" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Deals' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/dealpaymentinsuranceslist')" class="menu" *ngIf="global.WithModule('Deal Payment and Insurance Info')">
                <ion-text slot="start"></ion-text>
                <div slot="end">
                  <fa-icon [icon]="faShieldAlt" size="2x"></fa-icon>
                  <fa-icon [icon]="faCreditCard" size="2x"></fa-icon>
                </div>
                <ion-label>
                  <strong>{{ 'Deal Payment and Insurance Info' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/canceldealslist')" class="menu" *ngIf="global.WithModule('CancelDeals')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faBan" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Cancel Deals' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/canceldealapprovalslist')" class="menu" *ngIf="global.WithModule('CancelDealApprovals')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faThumbsUp" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Cancel Deal Approvals' }}</strong>
                </ion-label>
              </ion-item>

            <ion-item button (click)="MenuRoute('/taskchecklistslist')" class="menu" *ngIf="global.WithModule('Checklists')">
              <ion-text slot="start"></ion-text>
              <fa-icon slot="end" [icon]="faTasks" size="2x"></fa-icon>
              <ion-label>
                <strong>{{ 'Task Checklists' }}</strong>
              </ion-label>
            </ion-item>

              <ion-item button (click)="MenuRoute('/insurancecoverageslist')" class="menu" *ngIf="global.WithModule('InsuranceCoverages')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCalendarDay" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Deal Insurance Coverages' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/insuranceslist')" class="menu" *ngIf="global.WithModule('Insurances')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faShieldAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Insurances' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/dealreceivableslist')" class="menu" *ngIf="global.WithModule('DealReceivables')">
                <ion-text slot="start"></ion-text>
                <div slot="end">
                  <fa-icon [icon]="faHandHolding" size="2x"></fa-icon>
                  <fa-icon [icon]="faHandshake" size="2x"></fa-icon>
                </div>
                <ion-label>
                  <strong>{{ 'Loan Proceeds and Dealer Incentives' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/insurancerenewalslist')" class="menu" *ngIf="global.WithModule('InsuranceRenewals')">
                <ion-text slot="start"></ion-text>
                <div slot="end">
                  <fa-icon [icon]="faShieldAlt" size="2x"></fa-icon>
                  <fa-icon [icon]="faRetweet" size="2x"></fa-icon>
                </div>
                <ion-label>
                  <strong>{{ 'Insurance Renwewals' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/insurancecollectionslist')" class="menu" *ngIf="global.WithModule('InsuranceCollections')">
                <ion-text slot="start"></ion-text>
                <div slot="end">
                  <fa-icon [icon]="faHandHolding" size="2x"></fa-icon>
                  <fa-icon [icon]="faShieldAlt" size="2x"></fa-icon>
                </div>
                <ion-label>
                  <strong>{{ 'Insurance for Collections' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-item button (click)="Toggle('Marketing');" class="menu" *ngIf="global.WithModule('SuryveHeaders')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Marketing']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Marketing']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faBullhorn" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'MARKETING' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Marketing']" class="menu">
            <ion-menu-toggle>
              <ion-item button (click)="MenuRoute('/surveyheaderslist')" class="menu" *ngIf="global.WithModule('SurveyHeaders')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCheckDouble" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Surveys' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/surveyresponseslist')" class="menu" *ngIf="global.WithModule('SurveyResults')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCheckCircle" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Results' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-item button (click)="Toggle('Incentives');" class="menu" *ngIf="(global.GetUser('SystemRole')==='President') || (global.GetUser('SystemRole')==='General Manager') || (global.GetUser('SystemRole')==='Assistant General Manager') || (global.GetUser('UsersKey')==='***admin***')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Incentives']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Incentives']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faCoins" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'INCENTIVES (beta)' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Incentives'] && ((global.GetUser('SystemRole')==='President') || (global.GetUser('SystemRole')==='General Manager') || (global.GetUser('SystemRole')==='Assistant General Manager') || (global.GetUser('UsersKey')==='***admin***'))" class="menu">
            <ion-menu-toggle>

              <ion-item button (click)="MenuRoute('/comingsoon')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCalculator" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Incentives Calculator (demo)' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/comingsoon')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCar" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Tagging' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/teamincentiveslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faChess" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Team Incentives' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/designationincentiveslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faMapPin" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Designation / Position Incentives' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/sublevelincentiveslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faLayerGroup" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Sub-Level Incentives' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/techincentiveslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faMicrochip" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Tech Incentives' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-item button (click)="Toggle('HRIS');" class="menu" *ngIf="(global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff') || (global.GetUser('UsersKey')==='***admin***')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['HRIS']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['HRIS']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faIdCard" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'HRIS (beta)' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['HRIS']" class="menu">
            <ion-menu-toggle>

              <ion-item button (click)="MenuRoute('/myemployeeslist')" class="menu" *ngIf="(global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff') || (global.GetUser('UsersKey')==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faUserTie" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Employees' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/filingslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faFilePrescription" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'File an Employee Report' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/calendarslist')" class="menu" *ngIf="(global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff') || (global.GetUser('UsersKey')==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCalendarDay" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Calendar' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/taggingslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faTag" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Set Taggings' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/setdepartmentslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faChess" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Set Departments' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/comingsoon')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faMapPin" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Set Designations / Positions' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/taxbracketslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faFunnelDollar" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Tax Brackets' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/scheduleslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Schedules' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/employeereceivableslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faMoneyBillAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Employee Receivables' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/cashadvanceslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faFileInvoiceDollar" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Cash Advances' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/leaveslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faLeaf" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Leaves' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/leaveapprovalslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faLeaf" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Leave Approvals' }}</strong>
                </ion-label>
              </ion-item>

            </ion-menu-toggle>
          </ion-list>
        -->

          <ion-item button (click)="Toggle('Trainings');" class="menu" *ngIf="(global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff') || (global.GetUser('UsersKey')==='***admin***')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Trainings']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Trainings']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faDumbbell" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'TRAININGS & EXAMS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Trainings']" class="menu">
            <ion-menu-toggle>
              <ion-item button (click)="MenuRoute('/traininginstructorslist')" class="menu" *ngIf="(global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff') || (global.GetUser('UsersKey')==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faUserCircle" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'In-House Trainors' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/trainingslist')" class="menu" *ngIf="(global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff') || (global.GetUser('UsersKey')==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faBookOpen" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Trainings' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/trainingevaluationheaderslist')" class="menu" *ngIf="(global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff') || (global.GetUser('UsersKey')==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClipboardList" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Training Evaluations' }}</strong>
                </ion-label>
              </ion-item>

              <!-- <ion-item button (click)="MenuRoute('/examheaderslist')" class="menu" *ngIf="(global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff') || (global.GetUser('UsersKey')==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faMarker" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Exams' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/examscheduleslist')" class="menu" *ngIf="(global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff') || (global.GetUser('UsersKey')==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Exam Schedules' }}</strong>
                </ion-label>
              </ion-item> -->

              <ion-item button (click)="MenuRoute('/examcheckingslist')" class="menu" *ngIf="(global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff') || (global.GetUser('UsersKey')==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCheckDouble" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Exam Checkings' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-item button (click)="Toggle('Finances');" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - DCR') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives') || (global.GetUser('SystemRole') === 'Finance - Service, Parts & Others')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Finances']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Finances']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faCashRegister" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'FINANCE AND ACCOUNTING' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Finances']" class="menu">
            <ion-menu-toggle>
              <ion-item button (click)="MenuRoute('/dealerincentives')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faWarehouse" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Dealer Incentives' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/loanproceeds')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faPiggyBank" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Loan Proceeds' }}</strong>
                </ion-label>
              </ion-item>
    
              <ion-item button (click)="MenuRoute('/servicespartsothers')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Service, Parts & Others')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faToolbox" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Services, Parts, Others' }}</strong>
                </ion-label>
              </ion-item>

<!-- TODO -->
              <ion-item button (click)="MenuRoute('/dcrsap')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - DCR')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Daily Cashiers Report (SAP)' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/dcrexcel')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - DCR')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Daily Cashiers Report (Excel)' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-item button (click)="Toggle('FNAReports');" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - DCR') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives') || (global.GetUser('SystemRole') === 'Finance - Service, Parts & Others')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['FNAReports']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['FNAReports']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faChartPie" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'F&A REPORTS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['FNAReports']" class="menu">
            <ion-menu-toggle>
              <!-- <ion-item button (click)="MenuRoute('/dealerincentiveswithoutvsi')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faWarehouse" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Dealer Incentives without VSI' }}</strong>
                </ion-label>
              </ion-item> -->

              <ion-item button (click)="MenuRoute('/dealerincentivesformatchingvalidation')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faWarehouse" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Dealer Incentives for Matching Validation' }}</strong>
                </ion-label>
              </ion-item>

              <!-- <ion-item button (click)="MenuRoute('/loanproceedswithoutvsi')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faPiggyBank" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Loan Proceeds without VSI' }}</strong>
                </ion-label>
              </ion-item> -->

              <ion-item button (click)="MenuRoute('/loanproceedsformatchingvalidation')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faPiggyBank" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Loan Proceeds for Matching Validation' }}</strong>
                </ion-label>
              </ion-item>
    
              <!-- <ion-item button (click)="MenuRoute('/servicespartsotherswithoutsoa')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Service, Parts & Others')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faToolbox" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Services, Parts, Others without SOA' }}</strong>
                </ion-label>
              </ion-item> -->

              <!-- <ion-item button (click)="MenuRoute('/servicespartsothersteammembers')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Service, Parts & Others')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faToolbox" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Services, Parts, Others for Team Members' }}</strong>
                </ion-label>
              </ion-item> -->

              <ion-item button (click)="MenuRoute('/servicespartsothersformatchingvalidation')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Service, Parts & Others')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faToolbox" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Services, Parts, Others for Matching Validation' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/dcrsapformatchingvalidation')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - DCR')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Daily Cashiers Report (SAP) for Matching Validation' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/dcrexcelwithoutkey')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - DCR')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Daily Cashiers Report (Excel) without VSI / RO / PAI' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-item button (click)="Toggle('TrainingReports');" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.ToBoolean(global.GetUser('IsInstructor')) === true) || (global.GetUser('SystemRole')==='Human Resource Manager') || (global.GetUser('SystemRole')==='Human Resource Staff')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['TrainingReports']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['TrainingReports']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faChartPie" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'TRAINING & EXAM REPORTS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['TrainingReports']" class="menu">
            <ion-menu-toggle>
              <!-- <ion-item button (click)="MenuRoute('/dealerincentiveswithoutvsi')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faWarehouse" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Dealer Incentives without VSI' }}</strong>
                </ion-label>
              </ion-item> -->

              <ion-item button (click)="MenuRoute('/rpttrainingattendanceevaluations')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faMapMarkerAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Attendance & Evaluation' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/rptassessments')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClipboardList" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Assessment Form' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/rptmaterialaccesses')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faScrewdriver" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Material Accesses' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-item button (click)="Toggle('Uploads');" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - DCR') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives') || (global.GetUser('SystemRole') === 'Finance - Service, Parts & Others') || (global.GetUser('SystemRole')==='Human Resource Manager')">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Uploads']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Uploads']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faUpload" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'UPLOADS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Uploads']" class="menu">
            <ion-menu-toggle>
              <!--<ion-item button (click)="MenuRoute('/uplbiometricslist')" class="menu" *ngIf="global.WithModule('UplBiometrics')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faFingerprint" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Biometric Timesheets' }}</strong>
                </ion-label>
              </ion-item> -->

              <!-- <ion-item button (click)="MenuRoute('/uploadvehicledeliverieslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCar" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Deliveries' }}</strong>
                </ion-label>
              </ion-item> -->

              <ion-item button (click)="MenuRoute('/uploaddealerincentiveslist')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faWarehouse" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Dealer Incentives' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/uploadloanproceedslist')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Loan Proceeds & Dealer Incentives')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faPiggyBank" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Loan Proceeds' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/uploadservicespartsotherslist')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - Service, Parts & Others')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faToolbox" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Services, Parts, Others' }}</strong>
                </ion-label>
              </ion-item>

              <!-- <ion-item button (click)="MenuRoute('/uploadinquirieslist')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Group Manager')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faQuestionCircle" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Inquiries' }}</strong>
                </ion-label>
              </ion-item> -->

              <!--<ion-item button (click)="MenuRoute('/uploadpartsinvoiceslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faWpforms" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Parts Invoices' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/uploadvehiclesaleslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faCarSide" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Vehicle Sales' }}</strong>
                </ion-label>
              </ion-item>-->

              <ion-item button (click)="MenuRoute('/uploaddcrsaplist')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - DCR')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Daily Cashiers Report (SAP)' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/uploaddcrexcellist')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole') === 'Finance Manager') || (global.GetUser('SystemRole') === 'Finance - DCR')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faClock" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Daily Cashiers Report (Excel)' }}</strong>
                </ion-label>
              </ion-item>

              <!--<ion-item button (click)="MenuRoute('/uploadactivitieslist')" class="menu">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faPhoneSquareAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Activities' }}</strong>
                </ion-label>
              </ion-item> -->

              <ion-item button (click)="MenuRoute('/uploademployeeslist')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***') || (global.GetUser('SystemRole')==='Human Resource Manager')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faUserTie" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Employees (201)' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <!-- <ion-item button (click)="Toggle('Reports');" class="menu">
            <fa-icon slot="start" [icon]="faAngleRight" size="2x" *ngIf="!Menu['Reports']"></fa-icon>
            <fa-icon slot="start" [icon]="faAngleDown" size="2x" *ngIf="Menu['Reports']"></fa-icon>

            <ion-label>
              <fa-icon [icon]="faChartPie" size="2x"></fa-icon>
              &nbsp;
              <strong>{{ 'REPORTS' }}</strong>
            </ion-label>
          </ion-item>

          <ion-list *ngIf="Menu['Reports']" class="menu">
            <ion-menu-toggle>
              <ion-item button (click)="MenuRoute('/rptutilizationlist')" class="menu" *ngIf="(global.GetUser('UsersKey')==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faFirstOrderAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Utilization' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/rptactivitieslist')" class="menu" *ngIf="(global.WithModule('RptActivities')===true) || (global.GetUser('SystemRole') === 'MP (Marketing Professional)') || (global.GetUser('SystemRole') === 'Group Manager') || (global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.UsesKey==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faPhoneSquareAlt" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Activities' }}</strong>
                </ion-label>
              </ion-item>

              <ion-item button (click)="MenuRoute('/rptcustomerslist')" class="menu" *ngIf="(global.WithModule('RptCustomers')===true) || (global.GetUser('SystemRole') === 'MP (Marketing Professional)') || (global.GetUser('SystemRole') === 'Group Manager') || (global.GetUser('SystemRole') === 'Vehicle Sales Manager') || (global.GetUser('SystemRole') === 'Dealer Sales Trainer') || (global.UsesKey==='***admin***')">
                <ion-text slot="start"></ion-text>
                <fa-icon slot="end" [icon]="faSuitcase" size="2x"></fa-icon>
                <ion-label>
                  <strong>{{ 'Customers / Prospects' }}</strong>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list> -->

        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet main>
      <tluuploadform></tluuploadform>
    </ion-router-outlet>
  </ion-split-pane>
</ion-app>
