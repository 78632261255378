import * as i0 from "@angular/core";
var Config = /** @class */ (function () {
    function Config() {
        /*
            NOTE:
              DefaultURL
              TemplateURL
              Directory
            - use global.XMLEncode
        */
        this.ApplicationName = 'Toyota La Union';
        this.SchemaName = 'LaUnion';
        this.NotificationAppID = '0';
        this.User = new Array();
        this.PageSize = 500;
        this.EditablePageSize = 1000;
        this.Debug = true; // false
        this.Version = 'v. 20220714.2309';
        // this.SourceURL = 'http://localhost:8100/';
        // this.URL = 'http://alderaan/launion/r2t2post.php';
        // this.NotificationURL = 'http://alderaan/launion/onesignal.php';
        // this.UploadURL = 'http://alderaan/launion/tluupload.php';
        // this.DeleteURL = 'http://alderaan/launion/tludelete.php';
        // this.DefaultURL = 'http://alderaan/launion/uploads/';
        // this.TemplateURL = 'http://alderaan/launion/templates/';
        this.SourceURL = 'http://tluinitiative.com/';
        this.URL = 'http://tluinitiative.com/launion/r2t2post.php';
        this.NotificationURL = 'http://tluinitiative.com/launion/onesignal.php';
        this.UploadURL = 'http://tluinitiative.com/launion/tluupload.php';
        this.DeleteURL = 'http://tluinitiative.com/launion/tludelete.php';
        this.DefaultURL = 'http://tluinitiative.com/launion/uploads/';
        this.TemplateURL = 'http://tluinitiative.com/launion/templates/';
        this.Directory = 'C:\\inetpub\\wwwroot\\aofreightph\\uploads\\';
        this.UserDefaults();
    }
    Config.prototype.UserDefaults = function () {
        this.User['UsersKey'] = '';
        this.User['EmployeesKey'] = '';
        this.User['EmployeeName'] = '';
        this.User['FirstName'] = '';
        this.User['SystemRole'] = '';
        this.User['HasSubordinates'] = false;
        this.User['IsInstructor'] = false;
        this.User['Administrator'] = false;
        this.User['ModulesString'] = '';
        this.User['RightsString'] = '';
        this.User['Modules'] = new Array();
        this.User['Rights'] = {};
        this.User['PictureURL'] = '';
        this.User['TokenID'] = '';
    };
    Config.prototype.SetUserConfig = function (Config, Value) {
        if (Value === void 0) { Value = null; }
        if ((['Modules', 'Rights']).indexOf(Config) !== -1) {
            if (this.User[Config + 'String'] !== '') {
                this.User[Config] = JSON.parse(this.User[Config + 'String']);
            }
        }
        else {
            switch (typeof (this.User[Config])) {
                case 'boolean':
                    this.User[Config] = this.ToBoolean(Value);
                    break;
                case 'number':
                    this.User[Config] = this.ParseFloat(Value);
                    break;
                default:
                    this.User[Config] = Value;
                    break;
            }
        }
    };
    Config.prototype.IsUndefined = function (Parameter, Arbitrary) {
        if ((Parameter === undefined) &&
            (Arbitrary === undefined)) {
            return '';
        }
        else if ((Parameter === null) ||
            (Arbitrary === null)) {
            return '';
        }
        else if (Parameter === undefined) {
            return Arbitrary;
        }
        else if (typeof (Parameter) === 'string') {
            return (Parameter.trim());
        }
        else {
            return Parameter;
        }
    };
    Config.prototype.ToBoolean = function (Input) {
        if ((Input === undefined) ||
            (Input === null)) {
            return (false);
        }
        else {
            switch (Input.toString().toUpperCase()) {
                case '1':
                case 'TRUE':
                    return true;
                    break;
                default:
                    return false;
                    break;
            }
        }
    };
    Config.prototype.ParseFloat = function (number, AllowNegative) {
        if (AllowNegative === void 0) { AllowNegative = true; }
        if (this.IsUndefined(number, '') !== '') {
            var returnStr = void 0;
            returnStr = this.IsUndefined(number, '0').toString();
            returnStr = returnStr.replace(/,/g, '');
            if ((parseFloat(returnStr) < 0) &&
                (AllowNegative === false)) {
                return (0);
            }
            else {
                return (parseFloat(returnStr));
            }
        }
        else {
            return (0);
        }
    };
    Config.ngInjectableDef = i0.defineInjectable({ factory: function Config_Factory() { return new Config(); }, token: Config, providedIn: "root" });
    return Config;
}());
export { Config };
