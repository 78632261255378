<ion-content id="{{ ModuleName }}Container">
  <div class="background">
    <ion-grid *ngIf="!LoggingIn">
      <ion-row>
        <ion-col sizeXs="12" sizeSm="12" offsetMd="3" sizeMd="6" offsetLg="4" sizeLg="4" offsetXl="4" sizeXl="4">

          <ion-list class="logincontent">
            <ion-grid>
              <ion-row>
                <ion-col size="12">
                  <div class="ion-padding" style="text-align:center">
                    <img alt="logo" src="assets/imgs/banner.png" />
                  </div>
                </ion-col>

                <ion-col size="12">
                  <ion-item class="form required">
                    <ion-label>
                      <ion-icon class="glyphicon glyphicon-user"></ion-icon>
                    </ion-label>
                    <ion-input #UserCode type="text" [(ngModel)]="entity.UserCode" id="{{ ModuleName }}UserCode" name="{{ ModuleName }}UserCode" placeholder="User Code" autocomplete="off" autofill="off" maxlength="20" required></ion-input>
                  </ion-item>

                  <div id="UserCodeRequired" [class.hidden]="Errors['UserCodeRequired']===false" class="errorcontainer">
                    <div class="errormessage">
                      <ion-icon name="information-circle"></ion-icon>
                      &nbsp;&nbsp;&nbsp;{{ ' Please provide a value for User Code.' }}
                    </div>
                  </div>
                </ion-col>

                <ion-col size="12">
                  <ion-item class="form required">
                    <ion-label>
                      <ion-icon class="glyphicon glyphicon-lock"></ion-icon>
                    </ion-label>
                    <ion-input #UserPassword type="password" [(ngModel)]="entity.UserPassword" id="{{ ModuleName }}UserPassword" name="{{ ModuleName }}UserPassword" placeholder="Password" autocomplete="off" autofill="off" maxlength="20" required></ion-input>
                  </ion-item>

                  <div id="UserPasswordRequired" [class.hidden]="Errors['UserPasswordRequired']===false" class="errorcontainer">
                    <div class="errormessage">
                      <ion-icon name="information-circle"></ion-icon>
                      &nbsp;&nbsp;&nbsp;{{ ' Please provide a value for Password.' }}
                    </div>
                  </div>
                </ion-col>

                <ion-col size="12">
                  <div class="ion-padding">
                    <ion-button type="submit" color="primary" expand="block" shape="round" size="large" (click)="Login();">
                      <fa-icon [icon]="faSignInAlt" size="2x"></fa-icon>
                      &nbsp;
                      {{ 'LOGIN' }}
                    </ion-button>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="col-center">
                  <sub>{{ global.GetVersion() }}</sub>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-input type="hidden" id="IPAddress" name="IPAddress"></ion-input>
  </div>
</ion-content>